import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { initWeb3Onboard, initNotify } from './services'
import { useConnectWallet, useSetChain, useWallets } from '@web3-onboard/react'
import './App.css'
import Swal from 'sweetalert2'
import myNft from "./ABI.json"
import backgroundVideo from './videos/BackgroundVideo.mp4'
import image1 from './images/FeaturesOperationAccess00.png'
import image2 from './images/Comp20.png'
import closeIcon from './images/x.svg'
import axios from "axios";


const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS
const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY


let provider
const contractAddress = CONTRACT_ADDRESS; //rinkeby mainnet
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(ALCHEMY_API_KEY); 
let ethersProvider, walletProvider, walletSigner
let contract, contractInterface

const App = () => {
  const [{ wallet }, connect, disconnect] = useConnectWallet()
  const [{ chains, connectedChain, settingChain }, setChain] = useSetChain()
  const connectedWallets = useWallets()
  const [web3Onboard, setWeb3Onboard] = useState(null)
  const [notify, setNotify] = useState(null)    
  const [loading, setloading] = useState(false);
  const [userAddress, setUserAddress] = useState('')  
  const [ourMessge, setOurMessage]= useState('') 

  var passedTxnHash = ''

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
    setNotify(initNotify())
  }, [])

  useEffect(() => {   

    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )

    // Check for Magic Wallet user session
    if (connectedWalletsLabelArray.includes('Magic Wallet')) {
      const [magicWalletProvider] = connectedWallets.filter(
        provider => provider.label === 'Magic Wallet'
      )
      async function setMagicUser() {
        try {
          const { email } =
            await magicWalletProvider.instance.user.getMetadata()
          const magicUserEmail = localStorage.getItem('magicUserEmail')
          if (!magicUserEmail || magicUserEmail !== email)
            localStorage.setItem('magicUserEmail', email)
        } catch (err) {
          throw err
        }
      }
      setMagicUser()
    }
  }, [connectedWallets, wallet])

  useEffect(() => {
    if (!wallet?.provider) {
      provider = null
    } else {
      provider = new ethers.providers.Web3Provider(wallet.provider, 'any')      
    }
  }, [wallet])

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        await connect({ autoSelect: previouslyConnectedWallets[0] })
      }
      setWalletFromLocalStorage()
    }
  }, [web3Onboard, connect])  


  useEffect(() => {
    const currentlySelectedAddress = connectedWallets.map( ({accounts}) => accounts[0].address)
    //console.log('just checking', currentlySelectedAddress)
    setUserAddress(currentlySelectedAddress[0]) 
  })  

  const switchNetwork = async () => {   
   
    try {        
      await wallet.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }], // Check networks.js for hexadecimal network ids
      })
      setOurMessage('')

    } catch (error) {
      // This error code means that the chain we want has not been added to MetaMask
      // In this case we ask the user to add it to their MetaMask
      setOurMessage('')
      
      console.log(error)
    }    
  }

  const mintONE = async () => {
    askContractToMintNft(1);
  }  

  async function askContractToMintNft(quantity) {
    const currentlySelectedAddress = connectedWallets.map( ({accounts}) => accounts[0].address)

    //check if we have a conneted wallet
    if(currentlySelectedAddress.length !== 0){    

      if(connectedChain.id !== '0x1'){  
        setOurMessage('Please confirm the network change')
        
        await switchNetwork()         

      }else{          
        try {                  
          setloading(true)
          let provider = new ethers.providers.Web3Provider(wallet.provider);
          
          const signer = provider.getSigner()                 
          const contractInstance = new ethers.Contract(contractAddress, myNft.abi, provider)    

          async function getGasPrice() {
            let feeData = await provider.getFeeData()
            return feeData.gasPrice
          }

          async function getNonce(signer) {
            return (await signer).getTransactionCount()
          }

          if (currentlySelectedAddress.length !== 0) {            
            const nonce = await getNonce(signer)
            const gasFee = await getGasPrice()          

            let rawTxn = await contractInstance.populateTransaction.publicSaleMint(quantity, {
              /* global BigInt */  
              value: BigInt((1000000000000000000 * quantity).toString()),
              gasLimit: (285000 * quantity),
              nonce: nonce
            })
            console.log("Submitting transaction with gas price of:", ethers.utils.formatUnits(gasFee, "gwei"), "in gwei - & nonce:", nonce)
            let signedTxn = signer.sendTransaction(rawTxn)
            let reciept = (await signedTxn).wait()  

            const myHash = ((await signedTxn).hash) 
            const glass = () => {   //used to pass it as a global variabe for the catch to display the revert reason       
              passedTxnHash = myHash  
              return passedTxnHash          
            }
            glass()

            if (reciept) {          
              console.log("Transaction is successful!!!" + '\n' + "Transaction Hash:", (await signedTxn).hash + '\n' + "Block Number: " + (await reciept).blockNumber + '\n' + "Navigate to https://rinkeby.etherscan.io/tx/" + (await signedTxn).hash, "to see your transaction")
              Swal.fire({
                icon: 'success',
                iconColor: '#cfff48',
                title: 'Minting successful',
                text: 'Now that you have minted your foundation block you will be able to access a private token gated discord with further information and access to the entire team. \n\n' + 'Information collection to learn more about you and your project will begin on July 1st.',
                width: 600,
                padding: '3em',
                color: '#fff',
                background: '#12110D',
                buttonsStyling: false,
                confirmButtonText: `<a href='https://discord.gg/MmhjVakEbU' target="_blank" > JOIN DISCORD </a> `,                
                customClass: {
                    confirmButton: 'swal-confirm',
                },                 
              })             
                setloading(false) 
            } else {
              console.log("Error submitting transaction")          
              setloading(false)          
            }           
          }
          else {
              console.log("Need to connect a wallet first")          
              setloading(false)
              Swal.fire({      
                icon: 'question',
                iconColor: '#cfff48',
                title: 'Connect wallet',
                text: 'Before minting you must connect your wallet',        
                width: 600,
                padding: '3em',
                color: '#fff',
                background: '#12110D',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'swal-confirm',
                },        
              })
          }  
        } catch (e) {      
            
            
          if(e.message.includes("User denied transaction signature")){
            Swal.fire({
              icon: 'error',
              title: 'Minting Failed',
              text: 'Minting failed, you rejected the transaction, try again',   
              width: 600,
              padding: '3em',
              color: '#fff',
              background: '#12110D',
              buttonsStyling: false,
              customClass: {
                  confirmButton: 'swal-error',
              },      
            }) 
              setloading(false) 
              return 0;
          }else{
            setloading(false)             
          }               
            
          const txn = await web3.eth.getTransaction(passedTxnHash)
          console.log("transaction hash: ", passedTxnHash)
          
              
          let replay_tx = {
            to: txn['to'],
            from: txn['from'],
            value: txn['value'],
            data: txn['input'],
          }      
              
          try{
            const pullCall = await web3.eth.call(replay_tx, txn.blockNumber)
            console.log("Working ok", pullCall)
          }catch (error){
            
            if(error == "Error: Returned error: execution reverted: Public mint has not begun yet"){
              Swal.fire({
                icon: 'error',
                title: 'Minting Failed',
                text: 'Sale has not begun yet',
                width: 600,
                padding: '3em',
                color: '#fff',
                background: '#12110D',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'swal-error',
                },           
              })         
            }else if(error == "Error: Returned error: execution reverted: Exceeds max per transaction"){
              Swal.fire({
                icon: 'error',
                title: 'Minting Failed',
                text: 'Cannot mint more than 1 NFT at a time',
                width: 600,
                padding: '3em',
                color: '#fff',
                background: '#12110D',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'swal-error',
                },           
              })  
            }else if(error == "Error: Returned error: execution reverted: Incorrect funds"){
              Swal.fire({
                icon: 'error',
                title: 'Minting Failed',
                text: 'Incorrect funds sent',
                width: 600,
                padding: '3em',
                color: '#fff',
                background: '#12110D',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'swal-error',
                },           
              })  
            }else if(error == "Error: Returned error: execution reverted: Exceeds max per address"){
              Swal.fire({
                icon: 'error',
                title: 'Minting Failed',
                text: 'Cannot mint more than 1 NFT per wallet',
                width: 600,
                padding: '3em',
                color: '#fff',
                background: '#12110D',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'swal-error',
                },           
              })  
            }else if(error == "Error: Returned error: execution reverted: Must mint more than 0 tokens at a time"){
              Swal.fire({
                icon: 'error',
                title: 'Minting Failed',
                text: 'You must mint more than 0 NFTs',
                width: 600,
                padding: '3em',
                color: '#fff',
                background: '#12110D',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'swal-error',
                },           
              })  
            }else if(error == "Error: Returned error: execution reverted: No more NFTs left"){
              Swal.fire({
                icon: 'error',
                title: 'Minting Failed',
                text: 'All the NFTs are sold out',
                width: 600,
                padding: '3em',
                color: '#fff',
                background: '#12110D',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'swal-error',
                },           
              })  
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Minting Failed',
                text: 'Minting Failed - Try again',
                width: 600,
                padding: '3em',
                color: '#fff',
                background: '#12110D',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'swal-error',
                },           
              })    
            }       
          }   
          setloading(false)    
        }
      }
    }else{
      setloading(false)
      Swal.fire({      
        icon: 'question',
        iconColor: '#cfff48',
        title: 'Connect wallet',
        text: 'Before minting you must connect your wallet',        
        width: 600,
        padding: '3em',
        color: '#fff',
        background: '#12110D',
        buttonsStyling: false,
        customClass: {
        confirmButton: 'swal-confirm',
        },        
      })
    }
  }  



  const [opacityForHelpPopUp, setOpacityForHelpPopUp] = useState(0);
  const [displayForHelpPopUp, setDisplayForHelpPopUp] = useState('hidden');
  const [opacityForMoreInfoPopUp, setOpacityForMoreInfoPopUp] = useState(0);
  const [displayForMoreInfoPopUp, setDisplayForMoreInfoPopUp] = useState('hidden');

  const helpPopUpStyles = {  
    //this is to make the pop up window appear with an oapcity delay and disappear, and make the content hidden 
    opacity: opacityForHelpPopUp,
    visibility: displayForHelpPopUp,
    transition: 'opacity 1s ease-out, visibility 1s ease-out 0s'  
  };  

  const moreInfoPopUpStyles = {  
    //this is to make the pop up window appear with an oapcity delay and disappear, and make the content hidden 
    opacity: opacityForMoreInfoPopUp,
    visibility: displayForMoreInfoPopUp,
    transition: 'opacity 1s ease-out, visibility 1s ease-out 0s'       
  };  


  if (!web3Onboard || !notify) return <div>Loading...</div>

  return (
    <div className="HomeApp"> 

      <video autoPlay loop muted style={{position: "absolute",height:"100%", width:"100%", objectFit:"cover", zIndex:"-10" }}>
        <source src={backgroundVideo}  type="video/mp4" />  Your browser won't load the background video
      </video>  
      <div className="grad"></div>
      <a href='https://www.operationaccess.xyz/' ><div className="brand"></div></a>  

      <div className='blockNativeDivCSS'>

        <div className='selectChainDiv'> 
          {wallet && ( <div className="networkSelect"> <label>Switch Chains</label> {settingChain ? ( <span>Switching Chains...</span> ) : ( <select onChange={({ target: { value } }) => setChain({ chainId: value }) } value={connectedChain?.id} > {chains.map(({ id, label }) => { return (  <option value={id} key={id}> {label} </option> ) })} </select> )} </div> )}
        </div>        
          
        <div className='connectAndDisconnectDiv'>
          {!wallet && ( <button className="selectWalletButtonStyle" onClick={() => { connect() }} >  Connect  </button>  )}

          {/*wallet && ( <button className="blocknativeButton" onClick={() => { connect() }} >  Connect Another Wallet </button> )*/}

          {/*wallet && ( <button className="blocknativeButton" onClick={() => { disconnect(wallet)
            window.localStorage.removeItem('connectedWallets')
          }} > Disconnect Wallet </button>  )*/}

          {wallet && wallet?.dashboard && (<button className="blocknativeButton" onClick={wallet?.dashboard} > Open Wallet Dashboard </button> )}

          {wallet && wallet?.type === 'hardware' &&  wallet.accounts[0].address && ( <button className="blocknativeButton" onClick={web3Onboard.accountSelect} > Switch Account  </button> )}
        
        


          
          

        </div>  
      </div>  

      
      <div className="helpButtonLocationTurnOn">
        <button onClick={() => (setOpacityForHelpPopUp(1), setDisplayForHelpPopUp(''))} className="helpButtonStyleTurnOn">                
          Help
        </button>        
      </div> 

      <div className="moreInfoButtonLocationTurnOn">
        <button onClick={() => (setOpacityForMoreInfoPopUp(1), setDisplayForMoreInfoPopUp(''))} className="moreInfoButtonStyleTurnOn">                
          More Information 
        </button>        
      </div>      
      

      <div style={helpPopUpStyles}>
        <div className="helpDivCSS">      
          <h1 className="helpPopUpFont1">* OPERATION ACCESS *</h1>
          <h1 className="helpPopUpFont2">NEVER MINTED</h1>
          <h1 className="helpPopUpFont3">ANYTHING BEFORE?</h1>
          <p className="helpPopUpFont4">Don&#x27;t worry about it, it&#x27;s super easy.</p>  
          <div className="launchOnBoardLocation"> 
            <a href="https://www.operationaccess.xyz/onboarding.html" className="launch-onboard">Launch Onboarding</a>
          </div>   
          <div className="helpButtonLocationTurnOff">        
            <button onClick={() => (setOpacityForHelpPopUp(0), setDisplayForHelpPopUp('hidden'))} className="helpButtonStyleTurnOff">                
              <img className="closeIconSVG" src={closeIcon}></img>
            </button>            
          </div>      
        </div>
      </div>

      <div style={moreInfoPopUpStyles}>
        <div className="moreInfoDivCSS">   
        <h1 className="mint-deets">MINT: <span className="green">1 ETH</span></h1>
        <h1 className="mint-deets _2">DATE: <span className="green">24th JUNE</span></h1>
        <h1 className="mint-deets _3">TIME: <span className="green">4PM EST</span></h1>
        <img src={image1} alt= "image 1" className="image-2" ></img>
        <img src={image2} alt="image 2" className="image-mobile"></img>
 
          <div className="moreInfoButtonLocationTurnOff">        
            <button onClick={() => (setOpacityForMoreInfoPopUp(0), setDisplayForMoreInfoPopUp('hidden'))} className="moreInfoButtonStyleTurnOff">           
              <img className="closeIconSVGMoreInfo" src={closeIcon}></img>
            </button>            
          </div>      
        </div>
      </div>

      
      
      

      <div>                       
        {loading ? <div className="loading"> <div></div> <div></div> <div></div> <div></div> <div></div> </div> : ""}
        <div className={loading ? "mainContainer disabledPage" : "mainContainer"}>            
             
          <div className="mainContainer">   
            <div className="mintButtonLocation">
              <button onClick={mintONE} className="mintButtonStyle">                
                Mint for 1 ETH
              </button>              
            </div>    
          </div> 
        </div>  
      </div>   

      


    </div>
  )
}

export default App
